import React, { useState,useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import placeholderImg from '../../../../images/default-corporate-image.jpg'
import defaultGallery from '../../../../Assets/img/subtractbg.svg';
import subtractbgMob from '../../../../Assets/img/subtractbg-mob.svg';
import defaultGallery2 from '../../../../images/greenbg.png';
import greenbgMob from '../../../../Assets/img/greenbg-mob.png';
import defaultGallery3 from '../../../../images/bwsquare.png';
import download from '../../../../Assets/img/download-icon.svg';

// import detaultGalleryImage1 from '../../../../images/default-location-page-2.png';
import detaultGalleryImage2 from '../../../../images/default-location-page-3.png';
import {SlickArrowLeft, SlickArrowRight,s3url} from '../../../Admin/Utils';

const BannerSlider = ({locationData}) => {
    const [showLink, setShowLink] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowLink(true);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);
    const Lookingsettings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        prevArrow: <SlickArrowLeft currentSlide slideCount />,
        nextArrow: <SlickArrowRight currentSlide slideCount />,
    };
    //console.log(locationData.govt_development_plan.url)
    return(<>
        <section className="py-5 subtractbg">
            <div className='container position-relative subtractbgcontainer'>
                <div className='container'>
                    <div className='row bnrrow'>
                        <div className='col-4 d-flex justify-content-center'>
                            <img src={defaultGallery3}  className="img-fluid bwicon"/>
                        </div>
                        <div className='col-8 greenMobBox'>
                            <h1 className='d-flex flex-column align-items-md-start align-items-center century-font text-center text-md-start banner-head d-none d-md-block'>It’s All About {locationData?.name}!</h1>
                            <div className='d-block d-md-none'>
                                                    <div className='greenbgdiv'>
                            <div className='greenbgcont'>
                                <div className='intro'>Introduction</div>
                                <div className='bnrsubhead'>Here’s what the future for {locationData?.name} looks like</div>
                                {showLink && locationData?.govt_development_plan?.url &&  
                                    <div className='mt-3'>
                                        <a target='_blank' href={`${s3url}/location-assets${locationData?.govt_development_plan.url}`} className="explore-project-btn d-block mb-4">Gov. Development Plan <span><img src={download} className="img-fluid"/></span></a>
                                    </div>
                                }
                            </div>
                            <img src={defaultGallery2} className="img-fluid greenbg d-none d-md-block"/>
                            {/* <img src={greenbgMob} className="img-fluid greenbg d-block d-md-none w-100"/> */}
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bnrrow2 row'>
                    <div className='col-4 d-none d-md-block'>
                        <div className='greenbgdiv'>
                            <div className='greenbgcont'>
                                <div className='intro'>Introduction</div>
                                <div className='bnrsubhead'>Here’s what the future for {locationData?.name} looks like</div>
                                {showLink && locationData?.govt_development_plan?.url &&  
                                    <div className='mt-3'>
                                        <a href={`${s3url}/location-assets${locationData?.govt_development_plan.url}`} className="explore-project-btn d-block mb-4">Gov. Development Plan <span><img src={download} className="img-fluid"/></span></a>
                                    </div>
                                }
                            </div>
                            <img src={defaultGallery2} className="img-fluid greenbg d-none d-md-block"/>
                            <img src={greenbgMob} className="img-fluid greenbg d-block d-md-none"/>
                        </div>
                    </div>
                    <div className='col-12 col-md-8'>
                        <div className='banner-slider-wrap position-relative'>
                            <h1 className='d-flex flex-column align-items-md-start align-items-center century-font text-center text-md-start banner-head d-block d-md-none'>It’s All About {locationData?.name}!</h1>
                            <Slider {...Lookingsettings}>
                                {showLink && locationData?.gallery?.map((banner, index) => (
                                        <div className='looking-card-inner' key={index}>
                                            <div className='looking-for-card'>
                                                <div className='looking-for-img'>
                                                    <img src={`${s3url}/location-assets${banner.url}`} onError={(e) => { e.target.onerror = null; e.target.src = detaultGalleryImage2; }} className="img-fluid" height="540"/>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
                {/* <img src={defaultGallery} className="img-fluid patternbg d-none d-md-block"/> */}
                {/* <img src={subtractbgMob} className="img-fluid w-100 patternbg d-block d-md-none"/> */}
            </div>
        </section>
    </>);
};

export default BannerSlider;