import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { appAbsPath } from './../../Admin/Utils';

const StoryViewer = () => {
  const { slug } = useParams();

  useEffect(() => {
    const fetchAndRenderStory = async () => {
      try {
        const response = await fetch(
          `https://corporate.beyondwalls.com/wp-json/web-stories/v1/web-story?slug=${slug}`
        );
        const data = await response.json();
        
        if (data.length > 0) {
          const storyContent = data[0].content.rendered;
          const modifiedContent = updateSEOAndCanonical(data, slug);
          
          // Replace the entire document's content with the modified story content
          document.open();
          document.write(modifiedContent);
          document.close();
        } else {
          console.error("Story not found");
        }
      } catch (error) {
        console.error("Error fetching story:", error);
      }
    };

    fetchAndRenderStory();

    // Cleanup function
    return () => {
      // This will run when the component unmounts
      // Restore a basic HTML structure
      document.open();
      document.write('<html><head></head><body><div id="root"></div></body></html>');
      document.close();
    };
  }, [slug]);

  // This component doesn't render anything itself
  return null;
};

function updateSEOAndCanonical(htmlContent, slug) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent[0].content.rendered, 'text/html');

  // Update canonical URL
  let canonicalLink = doc.querySelector('link[rel="canonical"]');
  if (!canonicalLink) {
    canonicalLink = doc.createElement('link');
    canonicalLink.rel = 'canonical';
    doc.head.appendChild(canonicalLink);
  }
  canonicalLink.href = `${appAbsPath}/web-story/${slug}`;

  // Update or add SEO meta tags
  const metaTags = [
    { name: 'description', content: doc.querySelector('meta[name="description"]')?.content ||  htmlContent[0].excerpt.rendered},
    { property: 'og:title', content: doc.title },
    { property: 'og:description', content: doc.querySelector('meta[name="description"]')?.content || 'Web Story by Beyond Walls' },
    { property: 'og:url', content: `${appAbsPath}/web-story/${slug}` },
    { property: 'og:type', content: 'article' },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:title', content: doc.title },
    { name: 'twitter:description', content: doc.querySelector('meta[name="description"]')?.content || 'Web Story by Beyond Walls' },
  ];

  metaTags.forEach(({ name, property, content }) => {
    let meta = doc.querySelector(`meta[${name ? 'name' : 'property'}="${name || property}"]`);
    if (!meta) {
      meta = doc.createElement('meta');
      if (name) meta.name = name;
      if (property) meta.setAttribute('property', property);
      doc.head.appendChild(meta);
    }
    meta.content = content;
  });

  // Add structured data for Web Story
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `${appAbsPath}/web-story/${slug}`
    },
    "headline": doc.title,
    "description": doc.querySelector('meta[name="description"]')?.content || 'Web Story by Beyond Walls',
    "publisher": {
      "@type": "Organization",
      "name": "Beyond Walls",
      "logo": {
        "@type": "ImageObject",
        "url": `${appAbsPath}/logo.png`
      }
    }
  };

  const scriptTag = doc.createElement('script');
  scriptTag.type = 'application/ld+json';
  scriptTag.textContent = JSON.stringify(structuredData);
  doc.head.appendChild(scriptTag);

  return doc.documentElement.outerHTML;
}

export default StoryViewer;

