import React, { useContext } from 'react';
import { appAbsPath, s3url, getSortBHKConf, createGa4Event, formatNumber, getMinMaxPrice, parsedUserData, checkFileExtension, convertShortDate } from '../../Admin/Utils';
import { SearchVisibilityContext } from './SearchVisibilityContext';
import { Icon } from '@iconify/react';
import WishListBtn from '../Pages/WishListBtn';
import Gamification from '../Layouts/Gamification';
import { Link } from 'react-router-dom';
import defaultGallery from '../../../images/default-corporate-image.jpg';
import ReraImg from '../../../Assets/img/rera.svg';



const ProjectCard = ({ isWishlistActive, project, showWishlist, allowlazyload }) => {
    const { irfsAnimationStatus } = useContext(SearchVisibilityContext);
    const handlerelatedProj = (projectSlug) => {
        // alert(projectSlug);
        // window.location.reload();
        // window.scrollTo(0, 0);
        // navigate(`${appAbsPath}/project/${projectSlug}`);

        const url = project.is_irfs === true && irfsAnimationStatus ? `${appAbsPath}/irfs/project/${project.slug}` : `${appAbsPath}/project/${project.slug}`;
        window.open(url, '_blank');
        // <Navigate to={`${appAbsPath}/project/${projectSlug}`} />
    }

    const showEnquiryOverlay = (projectId, developerId, enquiry_triggered_from = '') => {
        const overlay = document.getElementById('overlayEnquiry');
        if (overlay) {
            overlay.style.display = 'block';
            document.getElementById('enquiry_project_id').value = projectId;
            document.getElementById('enquiry_developer_id').value = developerId;
            document.getElementById('enquiry_triggered_from').value = enquiry_triggered_from;
        }
    }

    return (
        <>
            <div className={`${project.is_irfs === true && project.offers && Array.isArray(project.offers) && project.offers.length > 0 && irfsAnimationStatus === true ? 'irfslist-class' : ''} position-relative`}>
                {project.is_irfs === true && project.offers && Array.isArray(project.offers) && project.offers.length > 0 && irfsAnimationStatus === true && <div className="irfs-exl">IRFS Exclusive Offer</div>}
                <div className="top-projects-card">
                    <div className="top-projects-img position-relative">
                        <Link onClick={() => { createGa4Event('top_projects', 'top_projects', `${project.name}`); handlerelatedProj(project.slug) }}>

                            {project && project.gallery_thumbnail && Array.isArray(project.gallery_thumbnail) && project.gallery_thumbnail.length > 0 ? (
                                (() => {
                                    const validImage = project.gallery_thumbnail.find(image => checkFileExtension(image.url));
                                    return validImage ? (
                                        <img
                                            {...(allowlazyload
                                                ? { 'data-src': `${s3url}/project-assets${validImage.url}`, src: `${defaultGallery}`, className: "img-fluid lazy" }
                                                : { src: `${s3url}/project-assets${validImage.url}`, className: "img-fluid" }
                                            )}
                                            alt={project.name}
                                        />
                                    ) : (
                                        <div
                                            className="lazyload-placeholder project-img"
                                            style={{ backgroundImage: `url(${defaultGallery})` }}
                                        ></div>
                                    );
                                })()
                            ) : project && project.gallery && Array.isArray(project.gallery) && project.gallery.length > 0 ? (
                                (() => {
                                    const validImage = project.gallery.find(image => checkFileExtension(image.url));
                                    return validImage ? (
                                        <img
                                            {...(allowlazyload
                                                ? { 'data-src': `${s3url}/project-assets${validImage.url}`, src: `${defaultGallery}`, className: "img-fluid lazy" }
                                                : { src: `${s3url}/project-assets${validImage.url}`, className: "img-fluid" }
                                            )}
                                            alt={project.name}
                                        />
                                    ) : (
                                        <div
                                            className="lazyload-placeholder project-img"
                                            style={{ backgroundImage: `url(${defaultGallery})` }}
                                        ></div>
                                    );
                                })()
                            ) : (
                                <img
                                    {...(allowlazyload
                                        ? { 'data-src': defaultGallery, className: "img-fluid lazy" }
                                        : { src: defaultGallery, className: "img-fluid" }
                                    )}
                                    alt={project.name}
                                />
                            )}
                        </Link>
                        {showWishlist === "yes" && (
                            <WishListBtn isWishlistActives={isWishlistActive} projectId={project.id} color={"#192951"} width={"30px"} height={"30px"} loggeduserdetails={parsedUserData} handleNotLogin />
                        )}
                        {window.location.href.includes('map-view') && (
                            <div className="rera-main-div">
                                {project.possession && (
                                    <p className='pos-date'><span>Possession:</span> {convertShortDate(project.possession)}</p>
                                )}
                                <div className='list-rerabox'>
                                    <p className="rera-txt">RERA Certified</p>
                                    <div className="zindex-10 top-picks">
                                        <div className="d-flex flex-column text-center">
                                            <span className="fw-bold">
                                                <Icon icon="mingcute:star-fill" className='mb-1' color={"#ffce31"} />
                                                {project.project_status ? project.project_status : 'Under Construction'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="top-projects-details">
                        <div>
                            <Link className='text-decoration-none devDataWrapper' onClick={() => { createGa4Event('top_projects', 'top_projects', `${project.name}`); handlerelatedProj(project.slug) }}>
                                <p className='dev-name text-truncate' title={project.developer.name}>{project.developer.name}</p>
                                <h3 className='prj-name col-12'>{project.name}</h3>
                                <p className='conf-desc col-12'>
                                    <Icon icon="ph:map-pin-bold" color="#6b6b6b" width="12px" style={{ "vertical-align": "baseline" }} />
                                    {getSortBHKConf(project)} Residences in {project?.locality_name}, {project?.address?.city}
                                </p>
                                <p className='rera-desc col-12'>
                                    <span>
                                        <img
                                            {...(allowlazyload
                                                ? { 'data-src': ReraImg, src: ReraImg, className: "img-fluid lazy" }
                                                : { src: ReraImg, className: "img-fluid" }
                                            )}
                                            alt="RERA certificate"
                                        />
                                    </span>
                                    RERA certificate No.
                                    <span className="psn-details">
                                        {(project?.rera_registration_no)?.split(',').join(', ').replace(/"/g, '').replace("[", '').replace("]", '')}
                                    </span>
                                </p>
                            </Link>
                            {/* {console.log('project12223', project)} */}
                            {/* <div className='pricebox'>
                                <p className='text-black mb-0'>
                                    {getMinMaxPrice(project) && getMinMaxPrice(project).minPrice !== null && formatNumber((getMinMaxPrice(project).minPrice).toFixed(2)) !== 0 ?
                                        <span className='project-price'>{`₹${formatNumber((getMinMaxPrice(project).minPrice).toFixed(2))} - ${getMinMaxPrice(project) && getMinMaxPrice(project).maxPrice !== null ? formatNumber((getMinMaxPrice(project).maxPrice).toFixed(2)) : ''}`}</span>
                                        :
                                        <span className="requesttitle" onClick={() => showEnquiryOverlay(project.id, project.developer_id,'Price on Request')}>Price on Request</span>}
                                </p>
                                <Link onClick={() => { createGa4Event('top_projects', 'top_projects', `${project.name}`); handlerelatedProj(project.slug) }} className='explore-btn'>
                                    <Icon icon="mdi:arrow-right" />
                                </Link>
                            </div> */}
                            {/* {console.log('ajjjj', project)} */}
                            <div className='pricebox'>
                                <p className='text-black mb-0'>
                                    {getMinMaxPrice(project) && getMinMaxPrice(project).minPrice !== null && formatNumber((getMinMaxPrice(project).minPrice).toFixed(2)) !== 0 ? (
                                        getMinMaxPrice(project).minPrice === getMinMaxPrice(project).maxPrice ? (
                                            <span className='project-price'>{`₹${formatNumber((getMinMaxPrice(project).minPrice).toFixed(2))}`}</span>
                                        ) : (
                                            <span className='project-price'>{`₹${formatNumber((getMinMaxPrice(project).minPrice).toFixed(2))} - ₹${formatNumber((getMinMaxPrice(project).maxPrice).toFixed(2))}`}</span>
                                        )
                                    ) : (
                                        <span className="requesttitle" onClick={() => showEnquiryOverlay(project.id, project.developer_id, 'Price on Request')}>
                                            Price on Request
                                        </span>
                                    )}
                                </p>
                                <Link onClick={() => {
                                    createGa4Event('top_projects', 'top_projects', `${project.name}`);
                                    handlerelatedProj(project.slug);
                                }} className='explore-btn'>
                                    <Icon icon="mdi:arrow-right" />
                                </Link>
                            </div>
                            {irfsAnimationStatus && (
                                <div className='py-2'>
                                    <Gamification projectDetails={project} />
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}


export default ProjectCard;