import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import dummyImage from "../../../images/webstory-dummy.png";
import { appAbsPath } from './../../Admin/Utils';

const WebStoriesListing = () => {
    const [stories, setStories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchStories = async () => {
            try {
                const response = await axios.get(
                    "https://corporate.beyondwalls.com/wp-json/web-stories/v1/web-story"
                );

                const storiesWithImages = await Promise.all(
                    response.data.map(async (story) => {
                        let imageUrl = dummyImage; // Default dummy image URL

                        if (story._links["wp:featuredmedia"] && story._links["wp:featuredmedia"][0].href) {
                            try {
                                const featuredMediaLink = story._links["wp:featuredmedia"][0].href;
                                const mediaResponse = await axios.get(featuredMediaLink);
                                imageUrl = mediaResponse.data.source_url;
                            } catch (error) {
                                console.error('Error fetching featured media:', error);
                            }
                        }

                        return {
                            ...story,
                            imageUrl,
                        };
                    })
                );

                setStories(storiesWithImages);
                setLoading(false);
                console.log(stories);
            } catch (err) {
                setError("Failed to load stories");
                setLoading(false);
            }
        };

        fetchStories();
    }, []);




    useEffect(() => {
        console.log(stories);
    }, [stories]);
    return (
        <div>
            <Helmet>
                <title>Web Stories Listing</title>
                <meta
                    name="description"
                    content="Discover the latest web stories about AI in real estate sales and more."
                />
                <meta name="keywords" content="web stories, AI, real estate, sales" />
                <meta property="og:title" content="Web Stories Listing" />
                <meta
                    property="og:description"
                    content="Explore engaging web stories related to real estate and AI."
                />
                <meta
                    property="og:url"
                    content={`${appAbsPath}/web-stories`}
                />
            </Helmet>

            <div className="container mt-5 pt-5 mb-5">
                <h1 className="mt-3 pb-3">Web Stories Listing</h1>
                {/* {loading && <p>Loading stories...</p>}
                    {error && <p style={{ color: "red" }}>{error}</p>} */}
                <div className="">
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                        {stories.map((story) => (
                            <Link to={`${appAbsPath}/web-story/${story.slug}`} target={"_blank"} key={story.id}>
                                <div

                                    style={{
                                        position: "relative",
                                        width: "300px",
                                        height: "400px",
                                        cursor: "pointer",
                                        borderRadius: "8px",
                                        overflow: "hidden",
                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",

                                    }}

                                >
                                    <img
                                        src={story.imageUrl}
                                        alt={story.title.rendered}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                            position: "absolute",
                                        }}
                                    />
                                    <div
                                        style={{
                                            background: "linear-gradient(180deg, #fff0, #000c)",
                                            display: "block",
                                            height: "100%",
                                            left: "0",
                                            position: "absolute",
                                            top: "0",
                                            width: "100%",
                                        }}
                                    >

                                    </div>

                                    <div
                                        style={{
                                            bottom: "0",
                                            color: "#fff",
                                            position: "absolute",
                                            zIndex: "1",
                                            padding: "10px",
                                        }}
                                    >
                                        <h3 style={{ margin: 0, fontSize: "1.2em" }}>
                                            {story.title.rendered}
                                        </h3>
                                        <p style={{
                                            marginTop: "8px",
                                            fontSize: "0.8em",
                                            "-webkit-line-clamp": "3",
                                            marginBottom: "8px"
                                        }}>
                                            {story.excerpt.rendered.split(' ').slice(0, 40).join(' ')}
                                        </p>
                                        <p style={{
                                            fontSize: "0.8em",
                                            color: "#ccc",
                                            margin: 0,
                                        }}>
                                            On {new Date(story.date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
                                        </p>

                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WebStoriesListing;
