import React, { useState, useEffect, useCallback,useRef } from 'react';
import Slider from 'rc-slider';
import { formatNumber, appAbsPath } from '../../Admin/Utils';
import { createGa4Event, isMobileDevice, executeLaravelFrontAPI } from '../../Admin/Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { setProjectStatusFilter, setProjectConfigurationFilter, setProjectMaxBudgetFilter } from '../../Admin/UserActivatyTracker';
import { Icon } from '@iconify/react';
import { Accordion, Row, Col } from 'react-bootstrap';
import MobileFilter from './MobileFilter';
import { use } from 'react';

const isMobile = isMobileDevice();

const NewFilterSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
 
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedPossessionStatus, setSelectedPossessionStatus] = useState([]);
  const [selectedBHK, setSelectedBHK] = useState([]);
  const [priceRange, setPriceRange] = useState([500000, 50000000]);
  const [tempPriceRange, setTempPriceRange] = useState([500000, 50000000]);
  const [builtUpArea, setBuiltRange] = useState([100, 5000]);
  const [tempBuiltUpArea, setTempBuiltUpArea] = useState([100, 5000]);
  const [selectedLocalities, setSelectedLocalities] = useState([]);
  const [filteredByBhk, setFilteredByBhk] = useState([]);
  const [showAllBhk, setShowAllBhk] = useState(false);
  const initialDisplayLimit = 20;
  const [sortCriteria, setSortCriteria] = useState('relevance');
  const [isPdp, setPdp] = useState(false);
  const [hasParams, setHasParams] = useState(false);

  const [activeKey, setActiveKey] = useState(null); // To control which panel is open
  const accordionRef = useRef(null);

  useEffect(() => {
    getProjectBhkTypeFunc();
    // initializeFiltersFromUrl();
  }, []); 

  const getProjectBhkTypeFunc = async () => {
    try {
      const result = await executeLaravelFrontAPI('bhkconfigurations', "", 'GET');
      setFilteredByBhk(result.data);
    } catch (error) {
      console.error('Error fetching BHK types:', error);
    }
  };

  // const initializeFiltersFromUrl = useCallback(() => {
  //   const params = new URLSearchParams(location.search);
  //   setSelectedStatus(params.get('project_status')?.split(',').filter(Boolean) || []);
  //   setSelectedPossessionStatus(params.get('possession_months')?.split(',').filter(Boolean) || []);
  //   setSelectedBHK(params.get('configuration')?.split(',').filter(Boolean).map(item => item.toUpperCase()) || []);
  //   const minPrice = parseInt(params.get('minprice') || '500000');
  //   const maxPrice = parseInt(params.get('maxprice') || '50000000');
  //   setPriceRange([minPrice, maxPrice]);
  //   setTempPriceRange([minPrice, maxPrice]);
  //   const minArea = parseInt(params.get('carpet_min') || '100');
  //   const maxArea = parseInt(params.get('carpet_max') || '5000');
  //   setBuiltRange([minArea, maxArea]);
  //   setTempBuiltUpArea([minArea, maxArea]);
  //   setSortCriteria(params.get('orderby') || 'relevance');
  // }, [location.search]);

  // useEffect(() => {
  //   initializeFiltersFromUrl();
  // }, [location.search, initializeFiltersFromUrl]);

  useEffect(() => {
          // setParams(getAllParams(location));
          let params = new URLSearchParams(location.search);
          let configurationValue = params.get('configuration');
          let projectStatusValue = params.get('project_status') ? params.get('project_status').replace('%2B', ' ') : '';
          let possessionStatusValue = params.get('possession_months');
          let minpriceValue = params.get('minprice');
          let maxpriceValue = params.get('maxprice');
          let minBuiltValue = params.get('carpet_min');
          let maxBuiltValue = params.get('carpet_max');
          let localitiesValue = params.get('locality_name');
          if (configurationValue) {
              let configurationArray = configurationValue.split(',');
              setSelectedBHK(configurationArray);
          }
          if (projectStatusValue) {
              let projectStatusArray = projectStatusValue.split(',');
              setSelectedStatus(projectStatusArray);
          }
          if (possessionStatusValue) {
              let possessionStatusArray = possessionStatusValue.split(',');
              // console.log("possessionStatusArray", possessionStatusArray);
              setSelectedPossessionStatus(possessionStatusArray);
          }
          if(localitiesValue){
              let localitiesArray = localitiesValue.split(',');
              setSelectedLocalities(localitiesArray);
          }else{
              setSelectedLocalities([]);
          }
          if (minpriceValue || maxpriceValue) {
              if (!maxpriceValue) {
                  if (minpriceValue > 50000000) {
                      setPriceRange([minpriceValue, minpriceValue])
                  }
                  else{
                      setPriceRange([minpriceValue, 50000000]);
                  }
              }
              else if (!minpriceValue) {
                  if (maxpriceValue < 500000){
                      setPriceRange([maxpriceValue, maxpriceValue]);    
                  }
                  else{
                      setPriceRange([500000, maxpriceValue]);
                  }
              }
              else {
                  setPriceRange([minpriceValue, maxpriceValue]);
              }
              // setPriceRange([minpriceValue, maxpriceValue]);
          }
          if (minBuiltValue || maxBuiltValue) {
              setBuiltRange([minBuiltValue, maxBuiltValue]);
          }
      }, [location.pathname, location.search]);

  const updateUrlAndFetchProjects = useCallback((newParams = {}) => {
    const params = new URLSearchParams(location.search);
    let hasChanges = false;
    // Helper to conditionally set or delete a parameter
    const setOrDeleteParam = (key, value, defaultValue) => {
      if (value !== null && value !== defaultValue) {
        if (!params.has(key) || params.get(key) !== value.toString()) {
          params.set(key, value.toString());
          hasChanges = true;
        }
      } else if (params.has(key)) {
        params.delete(key);
        hasChanges = true;
      }
    };

    // Update project_status
    setOrDeleteParam('project_status', selectedStatus.length > 0 ? selectedStatus.join(',') : null, null);

    // Update possession_months
    setOrDeleteParam('possession_months', selectedPossessionStatus.length > 0 ? selectedPossessionStatus.join(',') : null, null);

    // Update BHK-related parameters
    if (selectedBHK.length > 0) {
      const configurationTypes = selectedBHK.map(type => type.toLowerCase());
      const numericParts = configurationTypes.map(type => type.match(/^\d*\.?\d*/)[0] || '0');
      const nonNumericParts = configurationTypes.map(type => type.match(/[a-zA-Z]+$/)[0]);

      setOrDeleteParam('configuration', configurationTypes.join(','), null);
      setOrDeleteParam('bhk', [...new Set(numericParts)].join(','), null);
      setOrDeleteParam('type', nonNumericParts.join(','), null);
    } else {
      ['configuration', 'bhk', 'type'].forEach(key => setOrDeleteParam(key, null, null));
    }

    if(selectedLocalities.length > 0){
      setOrDeleteParam('locality_name', selectedLocalities.join(','), null);
      setOrDeleteParam('searchtype', 'locality_name', null);
    }else{
      setOrDeleteParam('locality_name', null, null);
      setOrDeleteParam('searchtype', null, null);
    }

    // Update price range
    setOrDeleteParam('maxprice', priceRange[1], 50000000);
    setOrDeleteParam('minprice', priceRange[0], 500000);

    // Update built-up area only if the value is different
    setOrDeleteParam('carpet_max', builtUpArea[1], 5000);
    setOrDeleteParam('carpet_min', builtUpArea[0], 100);

    // Update sorting criteria
    setOrDeleteParam('orderby', sortCriteria, 'relevance');

    // Only navigate if there are changes
    // if (hasChanges) {
    //   const newUrl = `${location.pathname}?${params.toString()}`;
    //   // debounce(() => navigate(newUrl), 300);
    //   navigate(newUrl);
    // }

    const newUrl = `${location.pathname}?${params.toString()}`;
    navigate(newUrl);
  }, [location.pathname, navigate, priceRange, builtUpArea, sortCriteria,selectedBHK,selectedStatus,selectedPossessionStatus]);


  useEffect(() => {
    updateUrlAndFetchProjects();
    const urlParams = new URLSearchParams(location.search);
    setHasParams(urlParams.toString().length > 0);
  }, [updateUrlAndFetchProjects]);

  useEffect(() => {
    if (location.search === '') {
      setHasParams(false);
    }else{
      setHasParams(true);
    }
  },[location.search]);

  

  const toggleStatus = useCallback((status) => {
    setSelectedStatus(prevStatus => {
      const newStatus = prevStatus.includes(status)
        ? prevStatus.filter(item => item !== status)
        : [...prevStatus, status];
      updateUrlAndFetchProjects({ project_status: newStatus.join(',') });
      return newStatus;
    });
  }, [updateUrlAndFetchProjects]);

  const togglePossessionStatus = useCallback((status) => {
    setSelectedPossessionStatus(prevStatus => {
      const newStatus = prevStatus.includes(status) ? [] : [status];
      updateUrlAndFetchProjects({ possession_months: newStatus.join(',') });
      return newStatus;
    });
  }, [updateUrlAndFetchProjects]);

  const toggleBhkType = useCallback((bhkType) => {
    setSelectedBHK(prevBhkType => {
      let newBhkType;
      if (prevBhkType.includes(bhkType)) {
        newBhkType = prevBhkType.filter(item => item !== bhkType);
      } else {
        const numericPart = bhkType.match(/^\d*\.?\d*/)[0];
        const filtered = prevBhkType.filter(item => !item.startsWith(numericPart));
        newBhkType = [...filtered, bhkType];
      }
      updateUrlAndFetchProjects({ configuration: newBhkType.join(',') });
      return newBhkType;
    });
  }, [updateUrlAndFetchProjects]);

  const handleSliderChange = (newRange) => {
    setTempPriceRange(newRange);
  };

  const handleSliderChangeArea = (newRange) => {
    setTempBuiltUpArea(newRange);
  };

  const handleSliderChangeCommitted = (newRange) => {
    setPriceRange(newRange);
    setTempPriceRange(newRange);
    setProjectMaxBudgetFilter(newRange[1]);
    createGa4Event('filter_budget', 'BudgetFilter', `${newRange[0]}-${newRange[1]}`);
    updateUrlAndFetchProjects();
  };

  const handleSliderChangeCommittedArea = (newRange) => {
    setBuiltRange(newRange);
    setTempBuiltUpArea(newRange);
    createGa4Event('filter_builtup', 'Built Up Filter', `${newRange[0]}-${newRange[1]}`);
    updateUrlAndFetchProjects();
  };

  const toggleShowAllBhk = () => {
    setShowAllBhk(!showAllBhk);
  };

  const sortProjectsByBhkType = (selectedBHK) => {
    // Implement sorting logic here if needed
  };

  const setSelectedTab = (tab) => {
    // Implement tab selection logic
  };

  const clearUrlParams = (state) => {
    if (state === true) {
      setTimeout(() => {
        const params = new URLSearchParams(location.search);
        localStorage.removeItem('selectedValues');
        ['configuration', 'bhk', 'type', 'minprice', 'maxprice', 'project_status', 'carpet_min', 'carpet_max', 'searchtype', 'locality_name', 'possession_months', 'orderby', 'nlp'].forEach(param => params.delete(param));
        setSortCriteria('relevance');
        setPriceRange([500000, 50000000]);
        setTempPriceRange([500000, 50000000]);
        setBuiltRange([100, 5000]);
        setTempBuiltUpArea([100, 5000]);
        setSelectedStatus([]);
        setSelectedPossessionStatus([]);
        setSelectedBHK([]);
        setShowAllBhk(false);
        setSelectedLocalities([]);

        const currentUrl = location.pathname;
        const newUrl = `${currentUrl}?${decodeURIComponent(params)}`;
        navigate(newUrl);
      }, 1000);
    }
  }

  useEffect(() => {
    if (!isMobile) {
      const handleScroll = () => {
        const fixedDivWrap = document.getElementById('sticky-map-rightsidebarwrap');
        const fixedDiv = document.getElementById('sticky-map-rightsidebar');
        const footer = document.getElementById('footerdiv');

        if (fixedDiv && footer) {
          const footerRect = footer.getBoundingClientRect();
          if (window.scrollY > 1 && footerRect.top > window.innerHeight) {
            fixedDiv.classList.add('stickySidebar');
            fixedDiv.style.position = 'fixed';
            fixedDiv.style.top = isMobile ? '114px' : '157px';
          } else if (footerRect.top <= window.innerHeight) {
            fixedDiv.classList.remove('stickySidebar');
            fixedDiv.style.position = 'absolute';
            fixedDiv.style.bottom = '0';
          } else {
            fixedDiv.classList.remove('stickySidebar');
            fixedDiv.style.position = 'relative';
            fixedDivWrap.classList.remove('flexibleHeight');
            fixedDivWrap.style.height = 'calc(100vh - 157px)';
          }
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
    if (window.location.pathname.includes('irfs')) {
      setPdp(true);
    }
  }, []);

  const getSortProjectFilter = (sortCriteria) => {
    setSortCriteria(sortCriteria);
    createGa4Event('filter_sort', 'SortFilter', sortCriteria);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (accordionRef.current && !accordionRef.current.contains(event.target)) {
        setActiveKey(null); // Collapse all Accordion panels
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <MobileFilter
          selectedStatus={selectedStatus}
          toggleStatus={toggleStatus}
          selectedPossessionStatus={selectedPossessionStatus}
          togglePossessionStatus={togglePossessionStatus}
          selectedBHK={selectedBHK}
          toggleBhkType={toggleBhkType}
          priceRange={priceRange}
          tempPriceRange={tempPriceRange}
          builtUpArea={builtUpArea}
          tempBuiltUpArea={tempBuiltUpArea}
          filteredByBhk={filteredByBhk}
          showAllBhk={showAllBhk}
          initialDisplayLimit={initialDisplayLimit}
          sortProjectsByBhkType={sortProjectsByBhkType}
          setSelectedTab={setSelectedTab}
          handleSliderChange={handleSliderChange}
          handleSliderChangeCommitted={handleSliderChangeCommitted}
          handleSliderChangeArea={handleSliderChangeArea}
          handleSliderChangeCommittedArea={handleSliderChangeCommittedArea}
          sortCriteria={sortCriteria}
          setSortCriteria={setSortCriteria}
          clearUrlParams={clearUrlParams}
          hasParams={hasParams}
        />
      ) : (
        <div className='filter-sticky-map1 pt-2 pb-2' id="filter-sticky1">
          <Row className='d-flex'>
            <Col md={7}>
              <input type="hidden" name="search" value="" id="plp-search-hidden" />
              <Accordion className='single-accordion float-start'  activeKey={activeKey}
      onSelect={(key) => setActiveKey(key)}
      ref={accordionRef}>
                {/* Project Status Accordion Item */}
                <Accordion.Item eventKey="0" className='p-0 accordion-item'>
                  <Accordion.Header className={`${selectedStatus.length > 0 && "active"}`}>Project Status</Accordion.Header>
                  <Accordion.Body>
                    <div className='filter-options'>
                      <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("New Project") ? 'active' : ''}`}
                        onClick={() => {
                          toggleStatus("New Project");
                          createGa4Event('Click', 'Project Status Filter', 'New Project');
                        }}>
                        New Project
                      </a>
                      <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("Under Construction") ? 'active' : ''}`}
                        onClick={() => {
                          toggleStatus("Under Construction");
                          setProjectStatusFilter("Under Construction");
                          createGa4Event('filter_project_status', 'ProjectStatusFilter', 'Under Construction');
                        }}>
                        Under Construction
                      </a>
                      <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("Ready To Move") ? 'active' : ''}`}
                        onClick={() => {
                          toggleStatus("Ready To Move");
                          setProjectStatusFilter("Ready To Move");
                          createGa4Event('filter_project_status', 'ProjectStatusFilter', 'Ready To Move');
                        }}>
                        Ready To Move
                      </a>
                      <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("New Launch") ? 'active' : ''}`}
                        onClick={() => {
                          toggleStatus("New Launch");
                          setProjectStatusFilter("New Launch");
                          createGa4Event('filter_project_status', 'ProjectStatusFilter', 'New Launch');
                        }}>
                        New Launch
                      </a>
                      <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("Nearing Possession") ? 'active' : ''}`}
                        onClick={() => {
                          toggleStatus("Nearing Possession");
                          setProjectStatusFilter("Nearing Possession");
                          createGa4Event('filter_project_status', 'ProjectStatusFilter', 'Nearing Possession');
                        }}>
                        Nearing Possession
                      </a>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                {/* Configuration Type Accordion Item */}
               
                <Accordion.Item eventKey="1" className='p-0 accordion-item'>
                  <Accordion.Header className={`${selectedBHK.length > 0 && "active"}`}>Configuration Type</Accordion.Header>
                  <Accordion.Body>
                    <div className='filter-options'>
                      {filteredByBhk.slice(0, showAllBhk ? filteredByBhk.length : initialDisplayLimit).map((BhkType, index) => (
                        BhkType.bedrooms && BhkType.type && (
                          <a key={index} className={`btn btn-outline-secondary me-2 mb-2 ${selectedBHK.includes(BhkType.bedrooms + " " + BhkType.type.toLowerCase()) ? 'active' : ''}`}
                            onClick={() => {
                              toggleBhkType(BhkType.bedrooms + " " + BhkType.type.toLowerCase());
                              sortProjectsByBhkType(selectedBHK);
                              setSelectedTab(BhkType.bedrooms + " " + BhkType.type.toLowerCase());
                              setProjectConfigurationFilter(BhkType.bedrooms + " " + BhkType.type.toLowerCase());
                              createGa4Event('filter_configuration_type', 'ConfigurationTypeFilter', BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                            }}>
                            {BhkType.bedrooms} {BhkType.type.toUpperCase()}
                          </a>
                        )
                      ))}
                      {filteredByBhk.length > initialDisplayLimit && (
                        <button className="p-1 pt-0 readmore text-primary text-decoration-underline fw-bold border-0 background-trasparent btn btn-link" onClick={toggleShowAllBhk}>
                          {showAllBhk ? '- Show Less' : '+ Show More'}
                        </button>
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                {/* Possession Status Accordion Item */}
                <Accordion.Item eventKey="2" className='p-0 accordion-item'>
                  <Accordion.Header className={`${selectedPossessionStatus.length > 0 && "active"}`}>Possession Status</Accordion.Header>
                  <Accordion.Body>
                    <div className='filter-options'>
                      <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus.includes('3') ? 'active' : ''}`}
                        onClick={() => {
                          togglePossessionStatus('3');
                          createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 3 months');
                        }}>
                        In 3 months
                      </a>
                      <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus.includes('6') ? 'active' : ''}`}
                        onClick={() => {
                          togglePossessionStatus('6');
                          createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 6 months');
                        }}>
                        In 6 months
                      </a>
                      <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus.includes('12') ? 'active' : ''}`}
                        onClick={() => {
                          togglePossessionStatus('12');
                          createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 1 year');
                        }}>
                        In 1 year
                      </a>
                      <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus.includes('24') ? 'active' : ''}`}
                        onClick={() => {
                          togglePossessionStatus('24');
                          createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 2 years');
                        }}>
                        In 2 years
                      </a>
                      <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus.includes('36') ? 'active' : ''}`}
                        onClick={() => {
                          togglePossessionStatus('36');
                          createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 3 years');
                        }}>
                        In 3 years
                      </a>
                      <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus.includes('60') ? 'active' : ''}`}
                        onClick={() => {
                          togglePossessionStatus('60');
                          createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 5 years');
                        }}>
                        In 5 years
                      </a>
                      <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus.includes('84') ? 'active' : ''}`}
                        onClick={() => {
                          togglePossessionStatus('84');
                          createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 7 years');
                        }}>
                        In 7 years
                      </a>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                {/* Budget Accordion Item */}
                <Accordion.Item eventKey="3" className='p-0 accordion-item'>
                  <Accordion.Header className={`${priceRange[0] !== 500000 && "active"} ${priceRange[1] !== 50000000 && "active"}`}>Budget</Accordion.Header>
                  <Accordion.Body>
                    <Slider
                      range
                      min={500000}
                      max={50000000}
                      value={tempPriceRange}
                      onChange={handleSliderChange}
                      onChangeComplete={handleSliderChangeCommitted}
                      step={50000}
                    />
                    <p className='d-flex justify-content-between'>
                      <span>{`₹${formatNumber(tempPriceRange[0])}`}</span>
                      <span>{`₹${formatNumber(tempPriceRange[1])}`}{tempPriceRange[1] === 50000000 && '+'}</span>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                {/* Built Up Area Accordion Item */}
                <Accordion.Item eventKey="4" className='p-0 accordion-item'>
                  <Accordion.Header className={`${builtUpArea[0] !== 100 && "active"} ${builtUpArea[1] !== 5000 && "active"}`}>Built Up Area (Sq.Ft.)</Accordion.Header>
                  <Accordion.Body>
                    <Slider
                      range
                      min={100}
                      max={5000}
                      value={tempBuiltUpArea}
                      onChange={handleSliderChangeArea}
                      onChangeComplete={handleSliderChangeCommittedArea}
                      step={15}
                    />
                    <p className='d-flex justify-content-between'>
                      <span>{`${tempBuiltUpArea[0]} Sq.Ft.`}</span>
                      <span>{`${tempBuiltUpArea[1]} Sq.Ft.`}{tempBuiltUpArea[1] === 5000 && '+'}</span>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col md={5}>
              <div className="d-flex row justify-content-end">
                <div className='col-lg-12 col-12 row'>
                  <div className='col-12  text-end d-inline-flex align-items-center justify-content-end  text-end gap-1'>
                    <span className='me-2 d-none d-md-block sort-by'>Sort by: </span>
                    <select className="form-select form-select-sm  form-select-sm d-none d-md-block rounded-pill" value={sortCriteria} onChange={(e) => getSortProjectFilter(e.target.value)} style={{ "width": "200px" }}>
                      <option value="relevance">Relevance</option>
                      <option value="recentlyLaunched">Recently Launched</option>
                      <option value="priceLowToHigh">Price: Low to High</option>
                      <option value="priceHighToLow">Price: High to Low</option>
                      <option value="sqftLowToHigh">Sq. Ft.: Low to High</option>
                      <option value="sqftHighToLow">Sq. Ft.: High to Low</option>
                      <option value="possessionDate">Possession Date (Recent First)</option>
                    </select>
                    {isPdp === true ? (
                      <a href={`${appAbsPath}/irfs/projects${location.search}`} className={` ms-2 text-decoration-underline border p-1  rounded-pill`} onClick={() => createGa4Event('On Click', 'List View', 'List View')}>
                        <Icon icon="uim:list-ul" style={{ "color": "rgb(107, 107, 107)", "width": "40px", "height": "25px" }} />
                      </a>
                    ) : (
                      <a href={`${appAbsPath}/projects${location.search}`} className={` ms-2 text-decoration-underline border p-1  rounded-pill`} onClick={() => createGa4Event('On Click', 'List View', 'List View')}>
                        <Icon icon="uim:list-ul" style={{ "color": "rgb(107, 107, 107)", "width": "40px", "height": "25px" }} />
                      </a>
                    )}
                    <a className={`text-primary ms-2 text-decoration-underline border p-1 border-primary border-orange rounded-pill`} onClick={() => createGa4Event('On Click', 'Map View', 'Map View')}>
                      <Icon className='i-icon' icon="uiw:map" style={{ "color": "", "width": "40px", "height": "25px" }} />
                    </a>
                    {
                      hasParams && (
                        <button className='btn sc py-1 h-auto' onClick={() => clearUrlParams(true)}>Clear all</button>
                      )
                    }
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default NewFilterSidebar;

